import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { PreloadAllModules, provideRouter, withDebugTracing, withPreloading } from "@angular/router";
import { routes } from "./app.routes";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient } from "@angular/common/http";
import { provideAnimations } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Configuration, KNgDataTableModule } from "@kortobaa-front/k-ng-datatable";
import { KAuthProvider } from "@kortobaa-front/authentication";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/services/auth.service";
import { PhoneCodePipe } from "../shared/pipes/phoneCode.pipe";
import { GlobalErrorHandler } from "./error-handler/global-error-handler.interceptor";
import { ArabicDatePipe } from "../shared/pipes/arabic-date.pipe";
import { DurationTimePipe } from "../shared/pipes/duration-time.pipe";
import { DatePipePipe } from "../shared/pipes/date-pipe.pipe";
import { checkLanguagePipe } from "../shared/pipes/checkLanguage.pipe";
import { MatButtonLoadingDirective } from "../shared/directives/mat-btn-loading.directive";

export const kNgDataTablePkgConfig: Configuration = {
  APIHost: environment.meetApiHost,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

//firebase config
import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);


export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules)
      // withDebugTracing(),
    ),
    provideAnimations(),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        // isolate: false,
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      })
    ),
    importProvidersFrom(KNgDataTableModule.forRoot(kNgDataTablePkgConfig)),
    importProvidersFrom(KNgDataTableModule),
    { provide: HTTP_INTERCEPTORS, useClass: GlobalErrorHandler, multi: true },
    AuthService,
    PhoneCodePipe,
    ArabicDatePipe,
    DurationTimePipe,
    DatePipePipe,
    checkLanguagePipe,
    MatButtonLoadingDirective,
    {
      provide: KAuthProvider.default,
      useValue: { API_URL: environment.meetApiHost, ForceAdminOtpLogin: environment.ForceAdminOtpLogin },
    },
  ],
};
