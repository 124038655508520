<ng-container *ngIf="!errorMessage; else hasError">
  <div class="layout d-flex">
    <aside class="side"
      [ngClass]="{ close: sideNavToggleBtn, sideRes: showSideNav ,langEn :_translate.getDefaultLang() == 'en'}"
      *ngIf="userLogin" id="sideNav" #sideNav>
      <app-side-menu [sideNavToggleBtn]="sideNavToggleBtn" [subMenu]="subMenu" (toggleSubMenu)="toggleSubMenu($event)"
        (sideNavToggleBtnRes)="showSideNav = $event"></app-side-menu>
      <div class="side-icon" (click)="toggleSideNav(sideNavToggleBtn ? false : true)">
        <mat-icon class="material-icons-round">
          <ng-container *ngIf="_translate.getDefaultLang() == 'ar';else langEn">
            {{ sideNavToggleBtn ? "chevron_left" : "chevron_right" }}
          </ng-container>
          <ng-template #langEn>
            {{ sideNavToggleBtn ? "chevron_right" : "chevron_left" }}
          </ng-template>
        </mat-icon>
      </div>
    </aside>
    <div class="main" (click)="subMenu = false" [ngClass]="{
        userLogin: userLogin,
        sideBar_toggle: userLogin && sideNavToggleBtn,
        sideRes: showSideNav
      }">
      <!-- header -->
      <app-header [sideNavToggleBtn]="sideNavToggleBtn" class="header" *ngIf="userLogin" [route]="route" [ngClass]="{
          userLogin: userLogin,
          sideBar_toggle: userLogin && sideNavToggleBtn
        }" (sideNavToggleBtnRes)="showSideNav = $event; sideNavToggleBtn = false"></app-header>
      <!-- notification -->
      <!-- <app-notification></app-notification> -->
      <!-- main content -->
      <app-loading-layout />
      <div class="content" [ngStyle]="{
          margin: userLogin ? '' : '0',
          height: userLogin ? 'auto' : '100%'
        }" [ngClass]="{
          userLogin: userLogin,
          sideBar_toggle: userLogin && sideNavToggleBtn
        }">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>

<!-- handle error message to reload page component -->
<ng-template #hasError>
  <app-reload-page [errorMessage]="errorMessage"></app-reload-page>
</ng-template>