import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const ReportsRoutes: Route[] = [
  {
    path: "reports/error-reports",
    loadComponent: () =>
      import("../../Feature/reports/pages/error-reports/error-reports.component").then(
        (mod) => mod.ErrorReportsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "reports/abuse-reports",
    loadComponent: () =>
      import("../../Feature/reports/pages/abuse-reports/abuse-reports.component").then(
        (mod) => mod.AbuseReportsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "reports/private-error-reports",
    loadComponent: () =>
      import("../../Feature/reports/pages/private-error-reports/private-error-reports.component").then(
        (mod) => mod.PrivateErrorReportsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "reports/private-abuse-reports",
    loadComponent: () =>
      import("../../Feature/reports/pages/private-abuse-reports/private-abuse-reports.component").then(
        (mod) => mod.PrivateAbuseReportsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "reports/claims",
    loadComponent: () =>
      import("../../Feature/reports/pages/claims-page/claims-page.component").then((mod) => mod.ClaimsPageComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
