<div class="header" [ngClass]="{
    sideBar_toggle: user.currentUser && sideNavToggleBtn,
    langEn: _translate.getDefaultLang() == 'en'
  }">
  <div class="header-content">
    <div class="d-flex menu">
      <mat-icon matSuffix class="menu-responsive material-icons-outlined" id="sideNavIcon"
        (click)="sideNavToggleBtnRes.emit(true)">
        menu
      </mat-icon>
      <h3 class="title-medium">{{ "title" | translate }}</h3>
    </div>
    <div class="notification d-flex gap-1">
      <app-language-icon></app-language-icon>

      <div class="notify-icon" (click)="openNotification(this.navFlag)" id="notify">
        <span class="counter" *ngIf="hasUnseenNotification && this.navFlag == 'close'">
          {{ hasUnseenNotification | notifictionNumber }}
        </span>
        <mat-icon matSuffix class="material-icons-outlined" id="notify"> notifications </mat-icon>
      </div>
      <div class="username body-medium" [ngStyle]="{
          direction: (user.currentUser.username | checkLanguage) ? 'rtl' : 'ltr'
        }">
        {{ user.currentUser.username }}
      </div>
    </div>
  </div>

  <!-- breadcrumb -->
  <ul class="breadcrumb">
    <ng-container *ngFor="let item of route; index as i">
      <li class="body-small" *ngIf="+item > 0 != true">
        <span class="icon" *ngIf="route.length >= 2 && i >= 1"> > </span>
        <a (click)="i >= 0 && i < route.length - 1 ? redirectTo(item) : ''" [ngClass]="{
            'disabled-header':
              item == 'modification-requests' ||
              item == 'meetings' ||
              item == 'pages' ||
              item == 'requests-join' ||
              item == 'teachers' ||
              item == 'notifications' ||
              item == 'reservation' ||
              item == 'appointments' ||
              item == 'edit-course-info' ||
              item == 'course-info' ||
              item == 'courses' ||
              item == 'reports'
          }">
          {{ "breadCrumb." + item | translate }}
        </a>
      </li>
    </ng-container>
  </ul>
</div>