import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingService } from "../../services/loading.service";
import { Subscription } from "rxjs/internal/Subscription";
import { KNgDataTableModule } from "@kortobaa-front/k-ng-datatable";

@Component({
  selector: "app-loading-layout",
  standalone: true,
  imports: [CommonModule, KNgDataTableModule],
  templateUrl: "./loading-layout.component.html",
  styleUrls: ["./loading-layout.component.scss"],
})
export class LoadingLayoutComponent {
  loadingSub$!: Subscription;
  loadingState: boolean = false;
  primaryColor = "var(--primary-color)";

  constructor(private _loading: LoadingService) {
    this.loadingSub$ = this._loading.getEventLoad().subscribe((res: any) => {
      this.loadingState = res;
    });
  }

  ngOnDestroy() {
    if (this.loadingSub$) this.loadingSub$.unsubscribe();
  }
}
