import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const MeetingsRoutes: Route[] = [
  {
    path: "meetings/active-meetings",
    loadComponent: () =>
      import("../../Feature/meetings/pages/active-meetings/active-meetings.component").then(
        (mod) => mod.ActiveMeetingsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "meetings/scheduled-meetings",
    loadComponent: () =>
      import("../../Feature/meetings/pages/scheduled-meetings/scheduled-meetings.component").then(
        (mod) => mod.ScheduledMeetingsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "meetings/ended-meetings",
    loadComponent: () =>
      import("../../Feature/meetings/pages/ended-meetings/ended-meetings.component").then(
        (mod) => mod.EndedMeetingsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "meetings/:status/:id/members",
    loadComponent: () =>
      import("../../Feature/meetings/pages/members-meeting/members-meeting.component").then(
        (mod) => mod.MembersMeetingComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
