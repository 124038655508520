import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profileId = new BehaviorSubject('');

  constructor() { }

  get currentProfileId(){
    return this.profileId;
  }
}
