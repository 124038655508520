import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const StudentssRoutes: Route[] = [
  {
    path: "students",
    loadComponent: () =>
      import("../../Feature/students/pages/students-list/students-list.component").then(
        (mod) => mod.StudentsListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "students/:id/student-info/wallet",
    loadComponent: () =>
      import("../../Feature/students/pages/student-wallet/student-wallet.component").then(
        (mod) => mod.StudentWalletComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    // path: "students/student-info/:id/",
    path: "students/:id/student-info",
    loadComponent: () =>
      import("../../Feature/students/pages/student-profile/student-profile.component").then(
        (mod) => mod.StudentProfileComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
