<div class="confirm-container">
  <!-- dialog header -->
  <div class="header">
    <h2 class="label-medium" *ngIf="data.title">{{ data.title }}</h2>
    <!-- close icon -->
    <button class="close-btn-icon" (click)="accept(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-icon *ngIf="data.icon" class="dialog-icon">{{ data.icon }}</mat-icon>
  <!-- dialog content -->
  <h3 class="label-small-2 content">
    {{ data.content }}
  </h3>
  <!-- dialog actions -->
  <div class="action-btns">
    <button mat-btn class="btn yes label-medium-2"
      [ngStyle]="{'--color': data.action.color ? data.action.color : primaryColor}" (click)="accept(true)">
      {{ "confirm-dialog." + data.action.accept | translate }}
    </button>
    <button mat-btn class="btn ignore label-medium-2" (click)="accept(false)">
      {{ "confirm-dialog."+ data.action.refuse | translate }}
    </button>
  </div>
</div>