import { Route } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { NotAuthGuard } from "./guards/notAuth.guard";
import { MeetingsRoutes } from "./routes/meetings.routes";
import { RequestsJoinRoutes } from "./routes/requests-join.routes";
import { ReportsRoutes } from "./routes/reports.routes";
import { TeachersRoutes } from "./routes/teachers.routes";
import { StudentsRoutes } from "./routes/students.routes";
import { AppointmentsRoutes } from "./routes/appointments.routes";
import { CoursesRoutes } from "./routes/courses.routes";

export const routes: Route[] = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "login",
    pathMatch: "full",
    loadComponent: () => import("../auth/pages/auth-frame/auth-frame.component").then((mod) => mod.AuthFrameComponent),
    data: { current: "login" },
    canActivate: [NotAuthGuard],
  },
  {
    path: "home",
    loadComponent: () => import("../home/home/home.component").then((mod) => mod.HomeComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "pages/:id",
    loadComponent: () => import("../CMS/pages/cms-page/cms-page.component").then((mod) => mod.CMSPageComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "notifications/student-notifications",
    loadComponent: () =>
      import("../Feature/notifications/pages/students-notifications/students-notifications.component").then(
        (mod) => mod.StudentsNotificationsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "notifications/teacher-notifications",
    loadComponent: () =>
      import("../Feature/notifications/pages/teachers-notifications/teachers-notifications.component").then(
        (mod) => mod.TeachersNotificationsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "modification-requests/available-requests",
    loadComponent: () =>
      import("../Feature/edit-data-requests/pages/available-requests/available-requests.component").then(
        (mod) => mod.AvailableRequestsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "modification-requests/rejected-requests",
    loadComponent: () =>
      import("../Feature/edit-data-requests/pages/rejected-requests/rejected-requests.component").then(
        (mod) => mod.RejectedRequestsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "update-version",
    loadComponent: () =>
      import("../Feature/settings/pages/update-version/update-version.component").then(
        (mod) => mod.UpdateVersionComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "payments",
    loadComponent: () =>
      import("../Feature/payments/pages/payments-report/payments-report.component").then(
        (mod) => mod.PaymentsReportComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "evaluations",
    loadComponent: () =>
      import("../Feature/evaluations/pages/private-session-evaluations/private-session-evaluations.component").then(
        (mod) => mod.PrivateSessionEvaluationsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "money-transfer-requests",
    loadComponent: () =>
      import("../Feature/money-transfer-requests/pages/money-transfer-requests/money-transfer-requests.component").then(
        (mod) => mod.MoneyTransferRequestsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  ...AppointmentsRoutes,
  ...RequestsJoinRoutes,
  ...TeachersRoutes,
  ...StudentsRoutes,
  ...MeetingsRoutes,
  ...ReportsRoutes,
  ...CoursesRoutes,
  { path: "**", redirectTo: "home" },
];
