import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { PlatformService } from "src/app/core/services/platform.service";

@Component({
  selector: "app-language-icon",
  standalone: true,
  imports: [CommonModule, MatIconModule, MatMenuModule],
  templateUrl: "./language-icon.component.html",
  styleUrls: ["./language-icon.component.scss"],
})
export class LanguageIconComponent {
  currentLang: string = localStorage.getItem("defaultLanguage") || "ar";

  constructor(public _platForm: PlatformService) {}

  useLanguage(language: string) {
    localStorage.setItem("defaultLanguage", language);
    this.currentLang = language;
    this._platForm.setLanguage();
  }
}
