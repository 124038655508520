import { Injectable } from "@angular/core";
import { Login, LoginService, OTPCredential, UserService } from "@kortobaa-front/authentication";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private _login: LoginService,
    public user: UserService
  ) {}

  // get token
  userToken() {
    return {
      headers: { Authorization: `Bearer ${this.user.getToken()}` },
    };
  }

  //login user
  async login(body: Login) {
    return await this._login.login(body, { url: "admin/login" });
  }

  async validateOtp(data: OTPCredential){
    return await this._login.validateOTP(data);
  }
}
