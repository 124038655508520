import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {


  //create  Load event
  loading = new Subject();

  //emit  Load form to  event
  emitEventLoad(value: boolean) {
    this.loading.next(value);
  }

  //get event emitter  Load to subscribe to
  getEventLoad() {
    return this.loading;
  }
}
