import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const RequestsJoinRoutes: Route[] = [
  {
    path: "requests-join/active-apps",
    loadComponent: () =>
      import("../../Feature/teachers-apps/pages/applied-requests/applied-requests.component").then(
        (mod) => mod.AppliedRequestsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "requests-join/pending-apps",
    loadComponent: () =>
      import("../../Feature/teachers-apps/pages/pending-list/pending-list.component").then(
        (mod) => mod.PendingListComponent
      ),

    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "requests-join/declined-apps",
    loadComponent: () =>
      import("../../Feature/teachers-apps/pages/rejected-requests/rejected-requests.component").then(
        (mod) => mod.RejectedRequestsComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
