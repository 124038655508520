import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const CoursesRoutes: Route[] = [
  {
    path: "teachers/:status/:teacherId/courses",
    loadComponent: () =>
      import("../../Feature/teachers/pages/courses-page/courses-page.component").then(
        (mod) => mod.CoursesPageComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "courses/course-info/:courseId",
    loadComponent: () =>
      import("../../Feature/courses/pages/course-info/course-info.component").then((mod) => mod.CourseInfoComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "courses/edit-course-info/:courseId",
    loadComponent: () =>
      import("../../Feature/courses/pages/edit-course/edit-course.component").then((mod) => mod.EditCourseComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "courses-list/:status",
    loadComponent: () =>
      import("../../Feature/courses/pages/courses-list/courses-list.component").then((mod) => mod.CoursesListComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "courses-expiration",
    loadComponent: () =>
      import("../../Feature/courses/pages/courses-expiration/courses-expiration.component").then((mod) => mod.CoursesExpirationComponent),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
