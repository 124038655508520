import { Route } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";

export const AppointmentsRoutes: Route[] = [
  {
    path: "appointments/:status",
    loadComponent: () =>
      import("../../Feature/appointments/pages/appointments-list/appointments-list.component").then(
        (mod) => mod.AppointmentsListComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "appointments/:status/:id/members",
    loadComponent: () =>
      import("../../Feature/teachers/pages/members-meeting/members-meeting.component").then(
        (mod) => mod.MembersMeetingComponent
      ),
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
];
